const _gtag = window.gtag || ((...params) => console.log("gtag", ...params));
const _fb = window.fbq || ((...params) => console.log("fbq", ...params));

export const pageView = ({ url }) => {
  _gtag("config", "UA-161602626-1", {
    page_path: `/${url}`,
  });
  _gtag("config", "AW-10865655890", {
    page_path: `/${url}`,
  });
  switch (url) {
    case "material":
      return _fb("track", "AddToCart");
    case "customize":
      return _fb("track", "CustomizeProduct");
    case "sound":
      return _fb("track", "ViewContent");
    default:
      return console.log("Not Tracking", { url });
  }
};

export const trackInitiateCheckout = () => {
  _fb("track", "InitiateCheckout");
};
export const trackAddPaymentInfo = () => {
  _fb("track", "AddPaymentInfo");
};

export const trackEvent = ({ category, action, label, value }) => {
  _gtag("event", action, {
    event_category: category,
    event_label: label,
    value: value,
  });
  if (category === "youtube" && action === "search") {
    return _fb("track", "Search");
  }
};

export const trackOrder = ({
  orderId,
  type,
  productName,
  productId,
  productPrice,
  orderPrice,
  quantity,
}) => {
  _gtag("event", "purchase", {
    transaction_id: orderId,
    checkout_option: type,
    value: orderPrice,
    currency: "BRL",
    items: [
      {
        id: orderId,
        name: productName,
        sku: productId,
        price: productPrice,
        quantity: quantity,
        currency: "BRL",
      },
    ],
  });
  _gtag("event", "conversion", {
    send_to: "AW-10865655890/Vqh0CLiZ1acDENKAk70o",
    value: orderPrice,
    currency: "BRL",
    transaction_id: orderId,
  });
  _fb("track", "Purchase", {
    value: orderPrice,
    currency: "BRL",
    contents: [
      {
        id: productId,
        quantity: quantity,
      },
    ],
    content_type: "product",
    checkout_option: type,
  });
};
