import { useMediaQueries } from "@react-hook/media-query";

const useScreenSize = () => {
  const { matches } = useMediaQueries({
    hasRestrictiveHeight: "(max-height: 600px)",
  });

  return matches;
};
export default useScreenSize;
