import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import errorImageUrl from "./images/error.svg";
import Button from "./Button";
import GradientText from "./GradientText";

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { eventId: null, helpText: undefined };
  }

  static getDerivedStateFromError(e) {
    return { hasError: true, helpText: e.helpText };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope((scope) => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="font-body bg-body text-body">
          <div className="container max-w-xl px-4 mx-auto">
            <section className="px-4 py-12 text-center">
              <img
                className="mx-auto max-w-auto md:max-w-sm"
                src={errorImageUrl}
                alt="Imagem de erro"
              />
              <h2 className="mt-8 mb-2 text-5xl text-gray-800 font-heading">
                Ops! Algo deu errado.
              </h2>
              <p className="mb-6 text-xl text-gray-700">
                {!this.state.helpText && (
                  <>
                    Infelizmente, algo não saiu como esperado. Nossos
                    desenvolvedores já foram notificados desse erro, mas você
                    pode enviar mais detalhes do que aconteceu clicando no botão
                    abaixo.
                  </>
                )}
                {this.state.helpText}
              </p>
              <Button
                label="Enviar mais detalhes"
                primary
                onClick={() =>
                  Sentry.showReportDialog({
                    eventId: this.state.eventId,
                    title: "OPS!",
                    subtitle: "Algo deu errado.",
                    subtitle2:
                      "Use esse formulário para nos contar o que aconteceu.",
                    labelName: "Nome",
                    labelEmail: "E-mail",
                    labelComments: "O que aconteceu?",
                    labelClose: "Fechar",
                    labelSubmit: "Enviar",
                    errorGeneric: "gen",
                    errorFormEntry: "form",
                    successMessage:
                      "Obrigado por nos contar o que aconteceu. Se necessário, entraremos em contato.",
                    onLoad: () => {
                      document.getElementById("id_name").placeholder =
                        "Seu nome";
                      document.getElementById("id_email").placeholder =
                        "Seu e-mail";
                      document.getElementById("id_comments").placeholder =
                        "Escreva seu erro aqui";
                    },
                  })
                }
              />
              <a
                href="/"
                rel="noopener noreferrer"
                className="inline-block mt-3 cursor-pointer"
              >
                <GradientText>Tente novamente</GradientText>
              </a>
            </section>
          </div>
        </div>
      );
    }

    //when there's not an error, render children untouched
    return this.props.children;
  }
}

export default ErrorBoundary;
