import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import "@ungap/url-search-params";

import App from "./App";
import "./index.css";

import ErrorBoundary from "./ErrorBoundary";
// import errorSvg from "./error.svg";
// import GradientText from "./GradientText";
// const Maintenance = () => (
//   <div className="flex items-center justify-center min-h-screen p-4 bg-gray-100 md:p-10 min-w-screen">
//     <div className="flex flex-col p-4 bg-white shadow md:flex-row md:items-center md:max-w-3xl">
//     <div className="md:w-2/3">
//         <GradientText className="block mb-8 text-4xl leading-none">
//           Estamos em manutenção
//         </GradientText>
//         <p>Estamos fazendo algumas mudanças pra melhorar nossos serviços.</p>
//         <p>Por favor, tente mais tarde.</p>
//       </div>

//       <div className="md:w-1/3">
//         <img src={errorSvg} alt="Erro" />
//       </div>
//     </div>
//   </div>
// );
window.AudioContext = window.AudioContext || window.webkitAudioContext;

const originalWindowErrorCallback = window.onerror;
window.onerror = function customErrorHandler(
  errorMessage,
  url,
  lineNumber,
  columnNumber,
  errorObject
) {
  console.log({ errorObject }, errorObject.message);
  if (
    errorObject.message.includes("No response from window - cleaned up") ||
    errorObject.message.includes(
      "target.className.indexOf is not a function"
    ) ||
    errorObject.message.includes("ResizeObserver loop limit exceeded")
  ) {
    return;
  }
  if (typeof originalWindowErrorCallback === "function") {
    return originalWindowErrorCallback(
      errorMessage,
      url,
      lineNumber,
      columnNumber,
      errorObject
    );
  }
  return false;
};
if (process.env.REACT_APP_ENVIROMENT === "production") {
  Sentry.init({
    dsn: "https://397da2fdde654e629616bfa9fb9e7878@sentry.io/1995652",
  });
}

window.Mercadopago.setPublishableKey(
  process.env.REACT_APP_MERCADO_PAGO_PUBLIC_KEY
);

ReactDOM.render(
  <ErrorBoundary>
    <App />
  </ErrorBoundary>,
  document.getElementById("root")
);
