import React from "react";
import CustomRange from "./CustomRange";

const Shapes = ({
  textColor,
  bars,
  onBarsChanged,
  barWidth,
  onBarWidthChanged,
  waveformHeight,
  onWaveformHeightChanged,
}) => {
  return (
    <div className="flex flex-col justify-between h-full">
      <div className="flex flex-col justify-between">
        <CustomRange
          label="Densidade"
          labelColor={textColor}
          min={30}
          max={900}
          values={[bars]}
          onChange={(values) => onBarsChanged(values[0])}
        />
        <CustomRange
          label="Grossura"
          labelColor={textColor}
          min={1}
          max={10}
          values={[barWidth]}
          onChange={(values) => onBarWidthChanged(values[0])}
        />
        <CustomRange
          label="Altura"
          labelColor={textColor}
          min={10}
          max={150}
          noSteps
          values={[waveformHeight]}
          onChange={(values) => onWaveformHeightChanged(values[0])}
        />
      </div>
      <span className="text-sm text-gray-500">
        Certas combinações de grossura e densidade podem afetar a percepção das
        cores que você está vendo.
      </span>
    </div>
  );
};

export default Shapes;
