import React from "react";
import pb from "pretty-bytes";
import Button from "./Button";

const createFileUrl = (file) =>
  (window.URL || window.webkitURL).createObjectURL(file);

const Player = ({
  soundUrl,
  file,
  youtubeVideoId,
  spotify,
  cancelSound,
  confirmSound,
}) => {
  return (
    <div className="p-4 bg-white">
      {soundUrl && (
        <div>
          <audio className="w-full my-6" controls controlsList="nodownload">
            <source src={soundUrl} type="audio/mp3" />
          </audio>
        </div>
      )}
      {file && (
        <div>
          <h2 className="text-xl font-semibold text-gray-900">{file.name}</h2>

          <p className="text-sm font-normal text-gray-600">{pb(file.size)}</p>
          <audio className="w-full my-6" controls controlsList="nodownload">
            <source src={createFileUrl(file)} type={file.type} />
          </audio>
        </div>
      )}
      {youtubeVideoId && (
        <div className="relative block h-0 p-0 mb-4 overflow-hidden aspect-ratio-16/9">
          <iframe
            className="absolute top-0 left-0 w-full h-full"
            title="youtube-preview"
            src={`https://www.youtube.com/embed/${youtubeVideoId}`}
            frameBorder="0"
            allowFullScreen
          ></iframe>
        </div>
      )}
      {spotify && (
        <div className="mb-4">
          <h2 className="text-xl font-semibold text-gray-900">
            {spotify.name}
          </h2>
          <p className="text-sm font-normal text-gray-600">{spotify.artist}</p>
          <audio className="w-full my-6" controls controlsList="nodownload">
            <source src={spotify.previewUrl} type="audio/mp3" />
          </audio>
          <p className="text-sm text-gray-600">
            Esse é o trecho de pré-audição liberado pelo Spotify. O seu quadro
            irá utilizar a música completa para gerar a arte final. Para ouvir a
            música completa no Spotify,{" "}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={spotify.openUrl}
              className="font-medium border-b border-gray-300 border-dashed cursor-pointer"
            >
              clique aqui
            </a>
            .
          </p>
        </div>
      )}
      {cancelSound && confirmSound && (
        <div className="flex items-center justify-between w-full p-2">
          <Button
            data-testid="cancel-sound"
            className="mr-2"
            label="Escolher outro som"
            onClick={cancelSound}
          />
          <Button
            data-testid="confirm-sound"
            primary
            className=""
            label="Continuar"
            onClick={confirmSound}
          />
        </div>
      )}
    </div>
  );
};

export default Player;
