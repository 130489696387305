import React from "react";
import cn from "classnames";

const GradientText = ({ children, className }) => {
  return (
    <span
      className={cn(
        "text-transparent bg-gradient-r-pink-purple bg-clip-text",
        className
      )}
    >
      {children}
    </span>
  );
};

export default GradientText;
