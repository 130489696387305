import React from "react";
import * as Azure from "@azure/storage-blob";
import createId from "uuid4";
import Div100vh from "react-div-100vh";

import GradientText from "./GradientText";
import Logo from "./Logo";
import Loading from "./Loading";

import useAPI from "./useAPI";
import SoundUpload from "./Sound.Upload";
import SoundRecord from "./Sound.Record";
import SoundYoutube from "./Sound.Youtube";
import SoundSpotify from "./Sound.Spotify";
import useTrackErrorAndThrow from "./useTrackErrorAndThrow";

const TYPE_MAPPING = {
  "x-wav": "wav",
};

const azureUpload = async (
  storageAccount,
  sasToken,
  file,
  id,
  type,
  onProgress
) => {
  const blobName = `${id}.${type}`;
  const blobURL = Azure.BlobURL.fromContainerURL(
    Azure.ContainerURL.fromServiceURL(
      new Azure.ServiceURL(
        `https://${storageAccount}.blob.core.windows.net?${sasToken}`,
        Azure.StorageURL.newPipeline(new Azure.AnonymousCredential())
      ),
      "sounds"
    ),
    blobName
  );
  const blockBlobURL = Azure.BlockBlobURL.fromBlobURL(blobURL);

  await blockBlobURL.upload(Azure.Aborter.none, file, file.size, {
    metadata: {
      originalName: encodeURIComponent(file.name),
    },
    progress: (p) => onProgress(Math.round((p.loadedBytes * 100) / file.size)),
  });
  return blockBlobURL.url;
};

const Sound = ({ setJSONUrl, setFrameText, setSourceType }) => {
  const api = useAPI();
  const trackAndThrowError = useTrackErrorAndThrow();
  const [sound, setSound] = React.useState();
  const [uploadProgress, setUploadProgress] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const [processing, setProcessing] = React.useState(false);
  React.useEffect(() => {
    if (!sound) {
      return;
    }

    const upload = async () => {
      const id = createId();
      const extension = sound.name.match(/\.[0-9a-z-]+$/gi)[0].split(".")[1];
      const type = TYPE_MAPPING[extension] || extension;
      setUploadProgress(0);
      setUploading(true);

      const {
        data: { storageAccount, sasToken },
      } = await api.post("/storage");
      const soundUrl = await azureUpload(
        storageAccount,
        sasToken,
        sound,
        id,
        type,
        (p) => setUploadProgress(p)
      );

      setUploadProgress(100);
      setUploading(false);
      setProcessing(true);
      const { data } = await api.get(
        `/soundtojson?url=${encodeURIComponent(soundUrl)}&type=${type}`
      );
      setProcessing(false);
      setJSONUrl(data.url);
    };
    upload().catch(trackAndThrowError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sound]);
  return (
    <>
      {(uploading || processing) && (
        <Div100vh className="absolute top-0 left-0 w-screen h-screen p-0 m-0 overflow-hidden bg-white">
          <Logo />
          <div className="absolute top-0 flex flex-col w-full h-full p-4 overflow-hidden">
            <div className="flex flex-col items-center justify-center flex-1 w-full h-full py-4">
              {uploading && (
                <h3
                  className="font-light text-gray-800"
                  style={{ fontVariantNumeric: "tabular-nums" }}
                >
                  <span className="font-thin" style={{ fontSize: "12rem" }}>
                    {uploadProgress}
                  </span>
                  <span className="text-xl text-gray-500">%</span>
                </h3>
              )}
              {processing && (
                <span className="text-xl text-gray-500">
                  Processando seu arquivo. <br />
                  Isso pode levar alguns minutos.
                </span>
              )}
              <Loading />
            </div>
          </div>
        </Div100vh>
      )}
      <div className="flex flex-col items-center flex-1 p-6 justify-evenly">
        <div className="leading-relaxed text-left text-gray-800">
          <h2 className="mb-4 text-2xl font-bold">
            <GradientText>Vamos lá!</GradientText>
          </h2>
          <h4 className="mb-2 font-semibold">
            Primeiro, nós precisamos do seu som.
          </h4>
          <ul className="mb-2 list-inside custom-list">
            <li className="text-indigo-500">
              <strong>Faça upload</strong>{" "}
              <span className="text-gray-800">de um arquivo de áudio</span>
            </li>
            <li className="text-orange-500">
              <span className="text-gray-800">Ou </span>
              <strong>grave</strong>
              <span className="text-gray-800"> a sua voz</span>
            </li>
            <li>
              <span className="text-gray-800">Ou </span>
              <span className="text-gray-800">
                pesquise e use músicas do
              </span>{" "}
              <strong className="text-red-500">YouTube</strong> ou{" "}
              <strong className="text-green-500">Spotify</strong>
            </li>
          </ul>
          <p className="pr-12 text-sm font-semibold leading-snug tracking-tight text-gray-600 ">
            Crie seu quadro gratuitamente para ver como fica. Se gostar, compre
            a partir de <GradientText>R$ 50,00</GradientText> e com{" "}
            <GradientText>FRETE GRÁTIS</GradientText>.
          </p>
        </div>

        <div className="grid w-full grid-cols-2 gap-4">
          <SoundUpload
            setSound={setSound}
            setFrameText={setFrameText}
            setSourceType={setSourceType}
          />
          <SoundRecord
            setSound={setSound}
            setFrameText={setFrameText}
            setSourceType={setSourceType}
          />
          <SoundYoutube
            setJSONUrl={setJSONUrl}
            setFrameText={setFrameText}
            setProcessing={setProcessing}
            setSourceType={setSourceType}
          />
          <SoundSpotify
            setJSONUrl={setJSONUrl}
            setFrameText={setFrameText}
            setProcessing={setProcessing}
            setSourceType={setSourceType}
          />
        </div>
      </div>
    </>
  );
};

export default Sound;
