import React from "react";
import Div100vh from "react-div-100vh";
import cn from "classnames";

import Player from "./Player";
import Input from "./Input";
import useAPI from "./useAPI";
import Logo from "./Logo";
import Loading from "./Loading";
import CloseButton from "./CloseButton";
import { trackEvent } from "./track";
import Button from "./Button";
import useTrackErrorAndThrow from "./useTrackErrorAndThrow";

const googleStuff = (
  <div className="text-xs text-gray-500">
    Ao utilizar esta pesquisa, você concorda com{" "}
    <a
      className="border-b border-gray-300 border-dashed cursor-pointer"
      href="https://www.youtube.com/t/terms"
      rel="noopener noreferrer"
      target="_blank"
    >
      os termos de serviço do YouTube
    </a>{" "}
    e a{" "}
    <a
      className="border-b border-gray-300 border-dashed cursor-pointer"
      href="https://policies.google.com/privacy"
      rel="noopener noreferrer"
      target="_blank"
    >
      política de privacidade do Google
    </a>
    .
  </div>
);

const canUseVideo = (v) => v.duration.hours === 0 && v.duration.minutes < 15;

const LongVideoSvg = ({ size = "small" }) => (
  <svg
    className={cn("fill-current", {
      "h-3 mr-1": size === "small",
      "h-10": size === "big",
    })}
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.9 5.68A7.96 7.96 0 0011 4.06V2H9v2.06a8 8 0 107.32 3.03l1.46-1.45-1.42-1.42-1.45 1.46zM10 18a6 6 0 100-12 6 6 0 000 12zM7 0h6v2H7V0zm5.12 8.46l1.42 1.42L10 13.4 8.59 12l3.53-3.54z"
      fillRule="evenodd"
    />
  </svg>
);

const SoundYoutube = ({
  setJSONUrl,
  setFrameText,
  setProcessing,
  setSourceType,
}) => {
  const api = useAPI();
  const trackAndThrowError = useTrackErrorAndThrow();
  const [searching, setSearching] = React.useState(false);
  const [text, setText] = React.useState("");
  const [search, setSearch] = React.useState();
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [videoPreview, setVideoPreview] = React.useState();
  const [video, setVideo] = React.useState();

  React.useEffect(() => {
    if (!search) {
      return;
    }
    const load = async () => {
      setLoading(true);
      trackEvent({
        category: "youtube",
        action: "search",
        value: search,
      });
      const { data } = await api.get(
        `/search-youtube?q=${encodeURIComponent(search)}`
      );
      setResults(data);
      setLoading(false);
    };
    load().catch(trackAndThrowError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  React.useEffect(() => {
    if (!video) {
      return;
    }
    const load = async () => {
      setProcessing(true);
      setSearching(false);
      trackEvent({
        category: "youtube",
        action: "use-video",
        value: `${video.id}-${video.title}`,
      });
      const { data } = await api.get(
        `${
          process.env.REACT_APP_SNQ_AUDIO2JSON_BASE_URL
        }&type=youtube&videoId=${encodeURIComponent(video.id)}`
      );

      setJSONUrl(data.url);
      setFrameText(video.title);
      setSourceType("youtube");
      setProcessing(false);
    };
    load().catch(trackAndThrowError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);
  return (
    <>
      <div
        role="button"
        data-testid="youtube"
        onClick={() => {
          trackEvent({
            category: "youtube",
            action: "open-youtube",
          });
          setSearching(true);
        }}
        className="flex flex-row items-center w-full h-20 col-span-2 p-4 font-bold text-white bg-red-500 rounded-lg cursor-pointer hover:bg-red-700"
      >
        <div className="p-2 mr-4 bg-red-300 rounded">
          <svg viewBox="0 0 24 24" className="w-4 h-4 text-white fill-current">
            <path
              fillRule="evenodd"
              d="M9.522 15.553V8.81l6.484 3.383-6.484 3.36zM23.76 7.641s-.235-1.654-.954-2.382c-.913-.956-1.936-.96-2.405-1.016C17.043 4 12.005 4 12.005 4h-.01s-5.038 0-8.396.243c-.47.055-1.492.06-2.406 1.016C.474 5.987.24 7.641.24 7.641S0 9.584 0 11.525v1.822c0 1.942.24 3.884.24 3.884s.234 1.653.953 2.382c.914.956 2.113.926 2.647 1.026 1.92.184 8.16.241 8.16.241s5.043-.007 8.401-.25c.47-.056 1.492-.061 2.405-1.017.72-.729.954-2.382.954-2.382s.24-1.942.24-3.885v-1.82c0-1.942-.24-3.885-.24-3.885z"
            />
          </svg>
        </div>
        <span className="text-xl font-light text-white">
          Escolher do YouTube
        </span>
      </div>
      {searching && (
        <Div100vh className="absolute top-0 left-0 w-screen h-screen p-0 m-0 overflow-hidden bg-white lg:max-w-sm">
          <Logo />
          <div className="absolute top-0 right-0 pt-6 pr-6">
            <CloseButton
              onClick={() => {
                setSearching(false);
              }}
            />
          </div>
          <div className="flex flex-col h-full p-4 overflow-hidden">
            <form
              className="flex-none"
              onSubmit={(e) => {
                e.preventDefault();
                setVideoPreview(undefined);
                setSearch(text);
              }}
            >
              <h2 className="self-center mb-6 text-2xl font-light leading-tight text-center text-gray-900">
                Pesquisar no YouTube
              </h2>
              <Input
                data-testid="youtube-search"
                autoFocus
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={loading}
                enterkeyhint="search"
                button={
                  <Button
                    type="submit"
                    data-testid="youtube-search"
                    primary
                    className={cn("border border-l-0 rounded-l-none", {
                      "border-pink-500": !loading,
                      "shadow-none": loading,
                    })}
                    disabled={loading}
                    label={
                      <span role="img" title="Search" aria-label="Search icon">
                        <svg
                          viewBox="0 0 20 20"
                          className="h-6 text-white fill-current"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.906 14.32a8 8 0 1 1 1.414-1.414l5.337 5.337-1.414 1.414-5.337-5.337zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                          />
                        </svg>
                      </span>
                    }
                  />
                }
              />
              {googleStuff}
              {!loading &&
                !videoPreview &&
                results.find((v) => !canUseVideo(v)) && (
                  <div className="flex items-center mb-2 ml-3 text-gray-800">
                    <LongVideoSvg size="small" />
                    <span className="text-xs font-semibold tracking-wide">
                      Vídeo bloqueado por ser muito longo
                    </span>
                  </div>
                )}
            </form>
            {loading && (
              <div className="flex flex-row items-center justify-center flex-1 w-full h-full">
                <Loading />
              </div>
            )}
            {!loading && !videoPreview && results.length > 0 && (
              <div className="flex-1 w-full max-h-full pb-4 overflow-x-scroll">
                {results.map((v) => (
                  <div
                    key={v.id}
                    data-testid={v.id}
                    className={cn(
                      "flex flex-wrap bg-white border-b border-blue-tial-100 m-2",
                      {
                        "cursor-pointer hover:bg-gray-200 shadow":
                          canUseVideo(v),
                        "bg-red-100 relative": !canUseVideo(v),
                      }
                    )}
                    onClick={() => canUseVideo(v) && setVideoPreview(v)}
                  >
                    {!canUseVideo(v) && (
                      <div className="absolute flex items-center w-full h-full">
                        <div className="absolute z-0 w-full h-full bg-black opacity-75"></div>
                        <span className="z-10 pl-10 text-white">
                          <LongVideoSvg size="big" />
                        </span>
                      </div>
                    )}
                    <div className="flex w-full m-4">
                      <div className="flex items-center">
                        <img
                          alt="thumbnail"
                          className="w-24 h-auto"
                          src={v.thumbnails.medium.url}
                        />
                        <div className="flex flex-col p-4">
                          <h3 className="font-bold text-md text-tial-400">
                            {v.title}
                          </h3>
                          <span className="text-sm font-light">
                            {v.channelName}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!loading && videoPreview && (
              <Player
                youtubeVideoId={videoPreview.id}
                cancelSound={() => setVideoPreview(undefined)}
                confirmSound={() => setVideo(videoPreview)}
              />
            )}
          </div>
        </Div100vh>
      )}
    </>
  );
};

export default SoundYoutube;
