import React from "react";

import CustomRange from "./CustomRange";
import Input from "./Input";

const emojiRegex = /\p{Extended_Pictographic}/ugi;

const Text = ({
  text,
  textColor,
  onTextChanged,
  textSize,
  onTextSizeChanged,
  textFont,
  onTextFontChanged,
}) => {
  const [hasEmojis, setHasEmojis] = React.useState(false);
  const onChange = (value) => {
    if (emojiRegex.test(value)) {
      value = value.replace(emojiRegex, "");
      setHasEmojis(true);
    } else {
      setHasEmojis(false);
    }
    onTextChanged(value);
  };
  return (
    <div className="flex flex-col justify-start">
      <Input
        label="Texto"
        labelColor={textColor}
        placeholder="Seu texto aqui"
        warning={hasEmojis ? "Emojis não podem ser utilizados e foram removidos automaticamente" : undefined}
        value={text}
        onChange={(e) => onChange(e.target.value)}
      />
      {text && (
        <>
          <Input
            label="Fonte"
            labelColor={textColor}
            options={["Open Sans", "Lato", "Helvetica", "Crimson Text"]}
            value={textFont}
            onChange={(e) => onTextFontChanged(e.target.value)}
          />
          <CustomRange
            label="Tamanho"
            labelColor={textColor}
            min={30}
            max={300}
            values={[textSize]}
            onChange={(values) => onTextSizeChanged(values[0])}
          />
        </>
      )}
    </div>
  );
};

export default Text;
