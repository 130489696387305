import React from "react";
import * as Sentry from "@sentry/browser";

export default () => {
  const [error, setError] = React.useState();
  React.useEffect(() => {
    if (!error) {
      return;
    }
    Sentry.captureException(error);
    setError(undefined);
    throw error;
  }, [error]);
  return setError;
};
