import React from "react";

const CloseButton = ({ onClick }) => {
  return (
    <button className="w-6 h-6" onClick={onClick}>
      <svg viewBox="0 0 20 20" className="text-gray-800 fill-current">
        <path
          fillRule="evenodd"
          d="M10 8.586L2.929 1.515 1.515 2.929 8.586 10l-7.071 7.071 1.414 1.414L10 11.414l7.071 7.071 1.414-1.414L11.414 10l7.071-7.071-1.414-1.414L10 8.586z"
        />
      </svg>
    </button>
  );
};

export default CloseButton;
