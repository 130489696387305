export const states = [
  { text: " ", id: "-" },
  { text: "Acre", id: "AC" },
  { text: "Alagoas", id: "AL" },
  { text: "Amapá", id: "AP" },
  { text: "Amazonas", id: "AM" },
  { text: "Bahia", id: "BA" },
  { text: "Ceará", id: "CE" },
  { text: "Distrito Federal", id: "DF" },
  { text: "Espírito Santo", id: "ES" },
  { text: "Goiás", id: "GO" },
  { text: "Maranhão", id: "MA" },
  { text: "Mato Grosso", id: "MT" },
  { text: "Mato Grosso do Sul", id: "MS" },
  { text: "Minas Gerais", id: "MG" },
  { text: "Pará", id: "PA" },
  { text: "Paraíba", id: "PB" },
  { text: "Paraná", id: "PR" },
  { text: "Pernambuco", id: "PE" },
  { text: "Piauí", id: "PI" },
  { text: "Rio de Janeiro", id: "RJ" },
  { text: "Rio Grande do Norte", id: "RN" },
  { text: "Rio Grande do Sul", id: "RS" },
  { text: "Rondônia", id: "RO" },
  { text: "Roraima", id: "RR" },
  { text: "Santa Catarina", id: "SC" },
  { text: "São Paulo", id: "SP" },
  { text: "Sergipe", id: "SE" },
  { text: "Tocantins", id: "TO" },
];
