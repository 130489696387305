import React from "react";

import { usePaymentInputs } from "react-payment-inputs";
import images from "react-payment-inputs/images";

import Input from "./Input";
import Alert from "./Alert";
import { trackAddPaymentInfo } from "./track";

const Payment = ({
  register,
  errors,
  paymentOption,
  disableInputs,
  paymentError,
  paymentOptions,
  installmentsOptions,
}) => {
  const {
    getCardNumberProps,
    getExpiryDateProps,
    getCVCProps,
    getCardImageProps,
  } = usePaymentInputs();
  const [tracked, setTracked] = React.useState(false);
  const [phoneMask, setPhoneMask] = React.useState("(099) 9 9999-9999");
  return (
    <>
      <div>
        <Input
          label="Nome Completo"
          name="nome"
          placeholder="Digite seu nome completo"
          ref={register}
          error={errors.nome?.message}
          disabled={disableInputs}
        />
        <Input
          label="Email"
          name="email"
          placeholder="Digite seu email"
          type="email"
          ref={register}
          error={errors.email?.message}
          disabled={disableInputs}
        />
        <Input
          label="Telefone celular"
          name="telefone"
          placeholder="Digite seu telefone celular"
          type="tel"
          mask={phoneMask}
          ref={register}
          error={errors.telefone?.message}
          disabled={disableInputs}
          onFocus={() => {
            setPhoneMask("(099) 9 9999-9999");
          }}
          onBlur={(e) => {
            if (
              e.target.value.match(/\d+/g)?.map(Number).join("").length === 11
            ) {
              setPhoneMask("(099) 9 9999-9999");
            } else {
              setPhoneMask("(099) 9999-9999");
            }
          }}
        />
        <Input
          label="CPF"
          name="cpf"
          placeholder="Digite seu CPF"
          ref={register}
          error={errors.cpf?.message}
          mask="999.999.999-99"
          inputMode="numeric"
          disabled={disableInputs}
        />
        <Input
          label="Forma de pagamento"
          ref={register}
          name="paymentOption"
          options={paymentOptions}
          radio
          disabled={disableInputs}
          onBlur={() => {
            if (tracked) {
              return;
            }
            setTracked(true);
            trackAddPaymentInfo();
          }}
        />
      </div>
      {paymentOption === "cc" && (
        <>
          <Input
            label="Nome"
            name="cardName"
            placeholder="Nome como escrito no cartão"
            ref={register}
            error={errors.cardName?.message}
            disabled={disableInputs}
          />
          <Input
            {...getCardNumberProps({
              onBlur: () => {
                if (tracked) {
                  return;
                }
                setTracked(true);
                trackAddPaymentInfo();
              },
            })}
            ref={(e) => {
              register(e);
              getCardNumberProps().ref.current = e;
            }}
            label={
              <span className="inline-flex items-center mr-1">
                <span>Número do Cartão</span>
                <svg className="ml-2" {...getCardImageProps({ images })} />
              </span>
            }
            placeholder=""
            error={errors.cardNumber?.message}
            disabled={disableInputs}
          />
          <div className="flex">
            <div className="flex-1">
              <Input
                {...getExpiryDateProps()}
                ref={(e) => {
                  register(e);
                  getExpiryDateProps().ref.current = e;
                }}
                label="Validade"
                placeholder=""
                error={errors.expiryDate?.message}
                disabled={disableInputs}
              />
            </div>
            <div className="flex-1">
              <Input
                {...getCVCProps()}
                ref={(e) => {
                  register(e);
                  getCVCProps().ref.current = e;
                }}
                label="CVC"
                error={errors.cvc?.message}
                disabled={disableInputs}
              />
            </div>
          </div>
          <Input
            ref={register}
            name="installments"
            options={installmentsOptions}
            disabled={disableInputs}
          />
          {paymentError && (
            <div className="mb-3">
              <Alert type="error" description={paymentError} />
            </div>
          )}
        </>
      )}
    </>
  );
};

export default Payment;
