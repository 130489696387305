import React from "react";
import Button from "./Button";

const getDigitableLineBlocks = (line) => [
  `${line.slice(0, 5)}.${line.slice(5, 10)}`,
  `${line.slice(10, 15)}.${line.slice(15, 21)}`,
  `${line.slice(21, 26)}.${line.slice(26, 32)}`,
  `${line.slice(32, 33)} ${line.slice(33)}`,
];

// authorized	O pagamento está pendente de captura.
// in_process	O pagamento está em processo de análise.
// pending	O usuário ainda não concluiu o processo de pagamento.
// approved	O pagamento foi aprovado e creditado.
const STATUS_MAP = {
  authorized: "Autorizado - aguardando captura",
  pending: "Aguardando pagamento",
};
const OrderConfirmation = ({
  type,
  bankslipUrl,
  digitableLine,
  PIXqrCode,
  PIXqrCodeBase64,
  status,
  orderId,
  reset,
}) => {
  const [copied, setCopied] = React.useState(false);
  React.useEffect(() => {
    let timeout;
    if (copied) {
      timeout = setTimeout(() => {
        setCopied(false);
      }, 3000);
      return () => clearTimeout(timeout);
    }
  }, [copied]);
  return (
    <div className="flex flex-col justify-start">
      <div className="flex flex-col items-center text-center">
        <svg
          className="w-16 h-16 mb-4 text-green-500 fill-current"
          viewBox="0 0 20 20"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M2.929 17.071c3.905 3.905 10.237 3.905 14.142 0 3.905-3.905 3.905-10.237 0-14.142-3.905-3.905-10.237-3.905-14.142 0-3.905 3.905-3.905 10.237 0 14.142zm12.728-1.414A8 8 0 104.343 4.343a8 8 0 0011.314 11.314zM4 10l2-2 3 3 5-5 2 2-7 7-5-5z"
            fillRule="evenodd"
          />
        </svg>
        <h2 className="text-4xl font-light text-gray-800">Pedido concluído</h2>
        <h3 className="mb-4 text-xl font-light text-gray-600">
          O número do seu pedido é
          <span className="font-normal text-gray-900"> #{orderId}</span>
        </h3>
      </div>
      <h4 className="mb-1 text-xl font-medium text-gray-800">
        O que acontece agora?
      </h4>
      {type === "bankslip" && (
        <>
          <p className="mb-2 text-gray-700">
            Agora é com você. O boleto foi gerado e enviado para o seu e-mail. O
            prazo de entrega começa a valer após a compensação do pagamento do
            boleto que pode levar até 3 dias úteis.
          </p>
          <p className="font-semibold text-gray-700">
            Se preferir visualizar ou imprimir o boleto agora,
            <a
              className="text-green-500 cursor-pointer"
              href={bankslipUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              clique aqui
            </a>
            .
          </p>
          <div className="px-4 mt-6 text-center text-gray-800 text-md">
            {digitableLine && (
              <span>{getDigitableLineBlocks(digitableLine).join(" ")}</span>
            )}
          </div>
          {window.navigator.clipboard && (
            <button
              onClick={() => {
                window.navigator.clipboard.writeText(digitableLine);
                setCopied(true);
              }}
              className="flex items-center self-end justify-center pr-5 mt-2 text-xs text-gray-800 bg-white focus:shadow-outline focus:outline-none"
            >
              <svg
                className="w-3 h-3 mr-1 text-green-500 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.973 2.595a3 3 0 00-5.946 0L5 3v1H3.994C2.894 4 2 4.893 2 5.994v12.012C2 19.106 2.893 20 3.994 20h12.012c1.1 0 1.994-.893 1.994-1.994V5.994C18 4.894 17.107 4 16.006 4H15V3l-2.027-.405zM5 6H4v12h12V6h-1v1H5V6zm5-2a1 1 0 100-2 1 1 0 000 2z"
                  fillRule="evenodd"
                />
              </svg>
              <span>{copied ? "Pronto!" : "Copiar linha digitável"}</span>
            </button>
          )}
        </>
      )}
      {type === "pix" && (
        <>
          <p className="mb-2 text-gray-700">
            Agora é com você. Faça o pagamento via PIX utilizando o QRCODE
            abaixo. O pagamento é processado na hora e seu pedido será aprovado
            imediatamente.
          </p>
          <p className="mb-2 text-gray-700">
            Caso deseje fechar esta janela, o código do Pix Copia e Cola foi
            enviado para o seu e-mail. O código é válido por 24 horas.
          </p>
          <div className="flex items-center justify-center px-4 mt-6 text-center text-gray-800 text-md">
            <img
              src={`data:image/jpeg;base64,${PIXqrCodeBase64}`}
              alt="qrcode para pagamento"
              className="w-64"
            />
          </div>
          {window.navigator.clipboard && (
            <button
              onClick={() => {
                window.navigator.clipboard.writeText(PIXqrCode);
                setCopied(true);
              }}
              className="flex items-center self-end justify-center pr-5 mt-2 text-xs text-gray-800 bg-white focus:shadow-outline focus:outline-none"
            >
              <svg
                className="w-3 h-3 mr-1 text-green-500 fill-current"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.973 2.595a3 3 0 00-5.946 0L5 3v1H3.994C2.894 4 2 4.893 2 5.994v12.012C2 19.106 2.893 20 3.994 20h12.012c1.1 0 1.994-.893 1.994-1.994V5.994C18 4.894 17.107 4 16.006 4H15V3l-2.027-.405zM5 6H4v12h12V6h-1v1H5V6zm5-2a1 1 0 100-2 1 1 0 000 2z"
                  fillRule="evenodd"
                />
              </svg>
              <span>
                {copied
                  ? "Copiado para sua área de transferência!"
                  : "Usar Pix Copia e Cola"}
              </span>
            </button>
          )}
        </>
      )}
      {type === "cc" && status === "approved" && (
        <>
          <p className="mb-2 text-gray-700">
            Agora é com a gente. Como você pagou com cartão de crédito, nós
            vamos colocar seu pedido em produção o mais rápido possível.
          </p>
        </>
      )}
      {type === "cc" && status === "in_process" && (
        <>
          <span className="my-2 text-lg font-bold text-gray-800">
            Seu pagamento está em análise! É importante que você verifique seu
            e-mail pra validar essa compra.
          </span>

          <p className="mb-2 text-gray-700">
            Nosso parceiro de pagamentos, o Mercado Pago, colocou seu pagamento
            em análise. Quando isso acontece, eles entram em contato via e-mail
            ou telefone pra validar algum dado e concretizar a compra.{" "}
            <span className="font-bold">
              Ah! Dá uma olhada na caixa de SPAM que o e-mail pode parar lá
              também.
            </span>
          </p>
        </>
      )}
      {type === "cc" && status !== "approved" && status !== "in_process" && (
        <>
          <p className="mb-2 text-gray-700">
            Seu pedido foi confirmado, mas o pagamento ainda não foi aprovado e
            está com status{" "}
            <strong>'{STATUS_MAP[status] || "Em análise"}'</strong>. Assim que o
            pagamento for aprovado, nós te aviseramos por e-mail. Seu pedido
            entra em produção logo em seguida.
          </p>
        </>
      )}
      <h4 className="mt-6 mb-1 text-xl font-medium text-gray-800">
        Como acompanho meu pedido?
      </h4>
      <p className="mb-2 text-gray-700">
        As atualizações no status do seu pedido serão feitas por e-mail. Caso
        não encontre nossas mensagens em sua caixa principal, confira na sua
        caixa de spam.
      </p>
      <p className="mb-2 text-gray-700">
        Em caso de dúvidas, entre em contato com a gente pelo chat,
        <a className="text-green-500" href="mailto:contato@somnoquadro.com.br">
          {" "}
          contato@somnoquadro.com.br
        </a>
        .
      </p>
      <p className="mb-4 text-gray-700">
        Agradecemos a sua compra. Clique no botão abaixo para criar outro
        quadro.
      </p>
      <Button
        primary
        onClick={reset}
        className="block px-10 py-3 mx-auto text-white uppercase rounded shadow bg-gradient-r-pink-purple hover:bg-pink-700 focus:shadow-outline focus:outline-none"
        label="Criar outro quadro"
      />
    </div>
  );
};

export default OrderConfirmation;
