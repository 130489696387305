import React from "react";
import Div100vh from "react-div-100vh";
import cn from "classnames";

import Player from "./Player";
import Input from "./Input";
import useAPI from "./useAPI";
import Logo from "./Logo";
import Loading from "./Loading";
import CloseButton from "./CloseButton";
import { trackEvent } from "./track";
import Button from "./Button";
import useTrackErrorAndThrow from "./useTrackErrorAndThrow";

const SoundSpotify = ({
  setJSONUrl,
  setFrameText,
  setSourceType,
  setProcessing,
}) => {
  const api = useAPI();
  const trackAndThrowError = useTrackErrorAndThrow();
  const [searching, setSearching] = React.useState(false);
  const [text, setText] = React.useState("");
  const [search, setSearch] = React.useState();
  const [results, setResults] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [songPreview, setSongPreview] = React.useState();
  const [song, setSong] = React.useState();
  React.useEffect(() => {
    if (!search) {
      return;
    }
    const load = async () => {
      setLoading(true);
      trackEvent({
        category: "spotify",
        action: "search",
        value: search,
      });
      const { data } = await api.get(
        `/search-spotify?q=${encodeURIComponent(search)}`
      );
      setResults(data);
      setLoading(false);
    };
    load().catch(trackAndThrowError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search]);
  React.useEffect(() => {
    if (!song) {
      return;
    }
    const load = async () => {
      setProcessing(true);
      setSearching(false);
      trackEvent({
        category: "spotify",
        action: "use-song",
        value: `${song.id}-${song.name}`,
      });

      const { data } = await api.get(
        `${
          process.env.REACT_APP_SNQ_AUDIO2JSON_BASE_URL
        }&type=spotify&id=${encodeURIComponent(
          song.id
        )}&artist=${encodeURIComponent(song.artist)}&name=${encodeURIComponent(
          song.name
        )}`
      );
      setJSONUrl(data.url);
      setFrameText(`${song.artist} - ${song.name}`);
      setSourceType("spotify", song.id);
      setProcessing(false);
    };
    load().catch(trackAndThrowError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [song]);
  return (
    <>
      <div
        role="button"
        data-testid="spotify"
        onClick={() => {
          trackEvent({
            category: "spotify",
            action: "open-spotify",
          });
          setSearching(true);
        }}
        className="flex flex-row items-center w-full h-20 col-span-2 p-4 font-bold text-white bg-green-500 rounded-lg cursor-pointer hover:bg-green-700"
      >
        <div className="p-2 mr-4 bg-green-300 rounded">
          <svg
            stroke="currentColor"
            fill="currentColor"
            className="w-4 h-4"
            strokeWidth="0"
            viewBox="0 0 16 16"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zm3.669 11.538a.498.498 0 0 1-.686.165c-1.879-1.147-4.243-1.407-7.028-.77a.499.499 0 0 1-.222-.973c3.048-.696 5.662-.397 7.77.892a.5.5 0 0 1 .166.686zm.979-2.178a.624.624 0 0 1-.858.205c-2.15-1.321-5.428-1.704-7.972-.932a.625.625 0 0 1-.362-1.194c2.905-.881 6.517-.454 8.986 1.063a.624.624 0 0 1 .206.858zm.084-2.268C10.154 5.56 5.9 5.419 3.438 6.166a.748.748 0 1 1-.434-1.432c2.825-.857 7.523-.692 10.492 1.07a.747.747 0 1 1-.764 1.288z"></path>
          </svg>
        </div>
        <span className="text-xl font-light text-white">
          Escolher do Spotify
        </span>
      </div>
      {searching && (
        <Div100vh className="absolute top-0 left-0 w-screen h-screen p-0 m-0 overflow-hidden bg-white lg:max-w-sm">
          <Logo />
          <div className="absolute top-0 right-0 pt-6 pr-6">
            <CloseButton
              onClick={() => {
                setSearching(false);
              }}
            />
          </div>
          <div className="flex flex-col h-full p-4 overflow-hidden">
            <form
              className="flex-none"
              onSubmit={(e) => {
                e.preventDefault();
                setSongPreview(undefined);
                setSearch(text);
              }}
            >
              <h2 className="self-center mb-6 text-2xl font-light leading-tight text-center text-gray-900">
                Pesquisar no Spotify
              </h2>
              <Input
                data-testid="spotify-search"
                autoFocus
                value={text}
                onChange={(e) => setText(e.target.value)}
                disabled={loading}
                enterkeyhint="search"
                button={
                  <Button
                    type="submit"
                    data-testid="youtube-search"
                    primary
                    className={cn("border border-l-0 rounded-l-none", {
                      "border-pink-500": !loading,
                      "shadow-none": loading,
                    })}
                    disabled={loading}
                    label={
                      <span role="img" title="Search" aria-label="Search icon">
                        <svg
                          viewBox="0 0 20 20"
                          className="h-6 text-white fill-current"
                        >
                          <path
                            fillRule="evenodd"
                            d="M12.906 14.32a8 8 0 1 1 1.414-1.414l5.337 5.337-1.414 1.414-5.337-5.337zM8 14A6 6 0 1 0 8 2a6 6 0 0 0 0 12z"
                          />
                        </svg>
                      </span>
                    }
                  />
                }
              />
            </form>
            {loading && (
              <div className="flex flex-row items-center justify-center flex-1 w-full h-full">
                <Loading />
              </div>
            )}
            {!loading && !songPreview && results.length > 0 && (
              <div className="flex-1 w-full max-h-full pb-4 overflow-x-scroll">
                {results.map((s) => (
                  <div
                    key={s.id}
                    data-testid={s.id}
                    className={cn(
                      "flex flex-wrap bg-white border-b border-blue-tial-100 m-2",
                      {
                        "cursor-pointer hover:bg-gray-200 shadow": true,
                      }
                    )}
                    onClick={() => setSongPreview(s)}
                  >
                    <div className="flex w-full m-4">
                      <div className="flex items-center">
                        <img
                          alt="thumbnail"
                          className="w-24 h-auto"
                          src={s.album.image}
                        />
                        <div className="flex flex-col p-4">
                          <h3 className="font-bold text-md text-tial-400">
                            {s.name}
                          </h3>
                          <span className="text-sm font-light">{s.artist}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!loading && songPreview && (
              <Player
                spotify={songPreview}
                cancelSound={() => setSongPreview(undefined)}
                confirmSound={() => setSong(songPreview)}
              />
            )}
          </div>
        </Div100vh>
      )}
    </>
  );
};

export default SoundSpotify;
