import React from "react";
import Div100vh from "react-div-100vh";
import Player from "./Player";
import Logo from "./Logo";
import { trackEvent } from "./track";
import Button from "./Button";

const allowedExtensions = ["x-wav", "wav", "mp3", "wma", "m4a", "flac"];

const SoundUpload = ({ setSound, setFrameText,setSourceType }) => {
  const fileInput = React.useRef(null);
  const [file, setFile] = React.useState();
  const invalidFileType = !allowedExtensions.find((e) =>
    file?.name.endsWith(e)
  );
  return (
    <>
      <label
        htmlFor="sound-upload"
        className="flex flex-col items-start justify-between h-40 p-4 font-bold text-white bg-indigo-500 rounded-lg cursor-pointer hover:bg-indigo-700"
      >
        <div className="p-2 mr-4 bg-indigo-300 rounded">
          <svg viewBox="0 0 20 20" className="w-4 h-4 text-white fill-current">
            <path
              fillRule="evenodd"
              d="M13 10v6H7v-6H2l8-8 8 8h-5zM0 18h20v2H0v-2z"
            />
          </svg>
        </div>
        <span className="text-xl font-light text-white">Upload</span>
        <input
          ref={fileInput}
          id="sound-upload"
          type="file"
          className="hidden"
          onChange={(e) => setFile(e.target.files[0])}
        />
      </label>

      {file && (
        <Div100vh className="absolute top-0 left-0 w-screen h-screen p-0 m-0 overflow-hidden bg-white lg:max-w-sm">
          <Logo />
          <div className="p-4">
            {invalidFileType && (
              <div className="p-4 text-center">
                <section className="text-center">
                  <p className="mb-6 text-xl text-gray-700">
                    Arquivo inválido. Por enquanto, só aceitamos arquivos de
                    áudio com as extensões:
                  </p>
                  <ul className="mb-6 font-mono text-gray-700 text-md">
                    {allowedExtensions.map((e) => (
                      <li key={e}>{e}</li>
                    ))}
                  </ul>
                  <Button
                    onClick={() => {
                      setFile();
                    }}
                    primary
                    label="Fechar"
                  />
                </section>
              </div>
            )}
            {!invalidFileType && (
              <Player
                file={file}
                confirmSound={() => {
                  trackEvent({
                    category: "upload",
                    action: "use-uploaded-file",
                  });
                  setSound(file);
                  setFrameText(file.name.split(".").slice(0, -1).join("."));
                  setSourceType("upload");
                  setFile(undefined);
                }}
                cancelSound={() => {
                  setFile(undefined);
                  fileInput.current.value = "";
                }}
              />
            )}
          </div>
        </Div100vh>
      )}
    </>
  );
};

export default SoundUpload;
