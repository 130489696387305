import React from "react";
import cn from "classnames";
import creditCardType from "credit-card-type";
import { useForm } from "react-hook-form";

import mercadoPagoLogo from "./images/mercadopago.png";
import useAPI from "./useAPI";
import useTrackErrorAndThrow from "./useTrackErrorAndThrow";
import { trackEvent, trackOrder } from "./track";
import { mapError, getTokenFromMP } from "./checkout.mercadopago";

import Input from "./Input";
import Button from "./Button";
import GradientText from "./GradientText";
import CheckoutShipping from "./Checkout.Shipping";
import CheckoutPayment from "./Checkout.Payment";
import checkoutSchema from "./checkout.schema";

const money = (m) =>
  m.toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
  });
const round = (n) => Math.ceil((n * 100).toFixed(2)) / 100;

let defaultValues = {
  quantity: 1,
  paymentOption: "cc",
};
// if (window.location.search.includes("d=1")) {
//   defaultValues = {
//     quantity: 1,
//     nome_destinatario: "Luis " + Date.now(),
//     cep: "01009907",
//     estado: "SP",
//     cidade: "São Paulo",
//     bairro: "Centro",
//     endereco: "Rua Líbero Badaró",
//     numero: "293",
//     complemento: "19 andar",
//     nome: "Luis Deschamps Rudge",
//     email: "luis@example.com",
//     telefone: "48999999999",
//     cpf: "97816638043",
//     paymentOption: "pix",
//     cardName: "APRO teste",
//     cardNumber: "4235647728025682",
//     expiryDate: "11/25",
//     cvc: "123",
//     installments: "1",
//   };
// }

const mapOrder = ({
  quantity,
  nome_destinatario,
  cep,
  estado,
  cidade,
  bairro,
  endereco,
  numero,
  complemento,
  nome,
  email,
  telefone,
  cpf,
  paymentOption,
  cardName,
  cardNumber,
  expiryDate,
  cvc,
  installments,
}) => ({
  quantity: parseInt(quantity),
  installments,
  customerName: nome,
  customerEmail: email,
  customerPhone: telefone,
  cpf,
  recipientName: nome_destinatario,
  cep,
  uf: estado,
  city: cidade,
  district: bairro,
  street: endereco,
  streetNumber: numero,
  addressComplement: complemento,
  paymentOption,
  cardName,
  cardNumber: cardNumber?.replace(/\s/g, ""),
  expiryDate: expiryDate?.replace(/\s/g, ""),
  cvc: cvc?.replace(/\s/g, ""),
});

const Section = ({ title, icon, children }) => (
  <div className="p-2 my-4 bg-gray-200 rounded shadow">
    <h4 className="flex items-center mb-4 text-xl font-semibold tracking-wider text-gray-800">
      <span className="flex-auto">{title}</span>
      {icon && <span className="w-10">{icon}</span>}
    </h4>
    <div>{children}</div>
  </div>
);

const StepCheckout = ({
  onOrderCompleted,
  onOrderFailed,
  size,
  material,
  jsonUrl,
  frameProps,
  totals,
  couponInfo,
  setCEP,
  setQuantity,
  couponQuote,
  setCouponQuote,
  loadingQuote,
  mainTab,
}) => {
  const [showCoupon, setShowCoupon] = React.useState(false);
  const [creatingOrder, setCreatingOrder] = React.useState(false);
  const [loadingAddress, setLoadingAddress] = React.useState(false);
  const [paymentError, setPaymentError] = React.useState();
  const [localCoupon, setLocalCoupon] = React.useState("");
  const [paymentOptions, setPaymentOptions] = React.useState([]);
  const [installmentsOptions, setInstallmentsOptions] = React.useState([]);
  const api = useAPI();
  const trackAndThrowError = useTrackErrorAndThrow();
  const { register, errors, handleSubmit, watch, setValue, getValues } =
    useForm({
      validationSchema: checkoutSchema,
      defaultValues,
    });
  const paymentOption = watch("paymentOption");
  const watchQuantity = watch("quantity");
  React.useEffect(() => {
    setQuantity(parseInt(watchQuantity) || 1);
    // eslint-disable-next-line
  }, [watchQuantity]);
  const disableInputs = creatingOrder || loadingQuote || loadingAddress;
  React.useEffect(() => {
    if (!totals) {
      return;
    }
    setPaymentOptions([
      // {
      //   id: "bankslip",
      //   text: `Boleto - ${money(totals.bankSlip)}`,
      // },
      {
        id: "cc",
        text: `Cartão de crédito até ${totals.maxInstallments}x sem juros`,
      },
      {
        id: "pix",
        text: `PIX - ${money(totals.pix)}`,
      },
    ]);
    setInstallmentsOptions(
      [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
        .slice(0, totals.maxInstallments)
        .map((i) => ({
          id: `${i}`,
          text: `${i}x de ${money(round(totals.creditCard / i))} sem juros`,
        }))
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totals]);
  React.useEffect(() => {
    setLocalCoupon(couponQuote?.coupon);
  }, [couponQuote]);

  return (
    <div className={cn("block relative h-full")}>
      <h4 className="mb-4 text-xl text-center">
        <GradientText>Frete grátis para todo o Brasil</GradientText>
      </h4>
      <form
        onSubmit={handleSubmit(async (data) => {
          const newOrder = mapOrder(data);
          console.log(newOrder);
          setCreatingOrder(true);
          setPaymentError();
          const order = {
            ...newOrder,
            coupon: couponQuote?.coupon,
            size,
            material,
            frameProps,
            jsonUrl,
          };
          if (newOrder.paymentOption === "cc") {
            order.cardType = creditCardType(order.cardNumber)[0].type;
            order.token = await getTokenFromMP({ ...newOrder, totals });
          }
          const { data: paymentIntent } = await api
            .post("/order", order)
            .catch(trackAndThrowError);
          setCreatingOrder(false);
          // authorized	O pagamento está pendente de captura.
          // in_process	O pagamento está em processo de análise.
          // pending	O usuário ainda não concluiu o processo de pagamento.
          // approved	O pagamento foi aprovado e creditado.

          if (
            ["approved", "authorized", "in_process", "pending"].includes(
              paymentIntent.status
            )
          ) {
            trackOrder(paymentIntent);
            onOrderCompleted(paymentIntent);
          } else {
            setPaymentError(mapError(paymentIntent.status));
            onOrderFailed();
          }
        })}
      >
        {material !== "download" && (
          <Section title="Quantidade">
            <Input
              ref={register}
              label="Quantos quadros iguais você quer?"
              name="quantity"
              options={[1, 2, 3, 4, 5]}
              disabled={disableInputs}
            />
            {totals?.quantityDiscount > 0 && (
              <GradientText className="text-lg">
                {totals.quantityDiscount}% de desconto!
              </GradientText>
            )}
            {totals?.quantityDiscount === 0 && (
              <GradientText className="text-sm">
                Até 20% de desconto levando mais de um quadro!
              </GradientText>
            )}
          </Section>
        )}
        <Section title="Entrega">
          <CheckoutShipping
            register={register}
            errors={errors}
            disableInputs={disableInputs}
            loadingAddress={loadingAddress}
            getAddressFromCEP={async () => {
              const { cep: theCEP } = getValues();
              if (!theCEP) {
                return;
              }
              setLoadingAddress(true);
              try {
                const { data } = await api.get("/cep", {
                  params: { cep: theCEP },
                });
                setValue("estado", data.uf);
                setValue("cidade", data.city);
                setValue("bairro", data.district);
                setValue("endereco", data.street);
                if (data.city) {
                  setCEP(theCEP);
                }
              } catch (error) {
                console.error("invalid cep search", error);
              }
              setLoadingAddress(false);
            }}
          />
        </Section>
        <div className="flex items-center justify-around mb-4">
          <span className="font-semibold tracking-wide text-green-600 uppercase">
            Pague com segurança
          </span>
          <img src={mercadoPagoLogo} className="h-16" alt="Mercado Pago" />
        </div>
        <Section
          title="Pagamento"
          icon={
            <svg
              viewBox="0 0 20 20"
              className="h-5 text-green-600 fill-current"
            >
              <path d="M11,14.7324356 C11.5978014,14.3866262 12,13.7402824 12,13 C12,11.8954305 11.1045695,11 10,11 C8.8954305,11 8,11.8954305 8,13 C8,13.7402824 8.40219863,14.3866262 9,14.7324356 L9,17 L11,17 L11,14.7324356 Z M13,6 C13,4.34314575 11.6568542,3 10,3 C8.34314575,3 7,4.34314575 7,6 L7,8 L13,8 L13,6 Z M4,8 L4,6 C4,2.6862915 6.6862915,0 10,0 C13.3137085,0 16,2.6862915 16,6 L16,8 L17.0049107,8 C18.1067681,8 19,8.90195036 19,10.0085302 L19,17.9914698 C19,19.1007504 18.1073772,20 17.0049107,20 L2.99508929,20 C1.8932319,20 1,19.0980496 1,17.9914698 L1,10.0085302 C1,8.8992496 1.8926228,8 2.99508929,8 L4,8 Z"></path>
            </svg>
          }
        >
          <CheckoutPayment
            register={register}
            errors={errors}
            paymentOption={paymentOption}
            paymentOptions={paymentOptions}
            installmentsOptions={installmentsOptions}
            disableInputs={disableInputs}
            paymentError={paymentError}
          />
        </Section>
        {showCoupon && (
          <Section title="Cupom de desconto">
            <div className="flex flex-row items-end justify-between pr-3">
              <Input
                label="Cupom"
                name="coupon"
                error={couponInfo?.error && couponInfo.message}
                enterkeyhint="go"
                disabled={disableInputs || couponQuote.coupon}
                value={localCoupon || ""}
                onChange={(e) => setLocalCoupon(e.target.value)}
                onKeyPress={(e) => {
                  if (e.keyCode === 13) {
                    e.preventDefault();
                    setCouponQuote({
                      coupon: localCoupon,
                      cpf: getValues().cpf,
                    });
                  }
                }}
              />
              <button
                type="button"
                data-testid="apply-coupon"
                className="flex-1 w-full h-10 px-3 py-1 mb-3 leading-tight text-gray-700 bg-white border rounded rounded-r-none shadow cursor-pointer focus:outline-none focus:border-blue-300 focus:border-r-0 disabled:opacity-50"
                disabled={disableInputs}
                onClick={() => {
                  setCouponQuote({
                    coupon: localCoupon,
                    cpf: getValues().cpf,
                  });
                }}
              >
                Aplicar
              </button>
            </div>
            <div className="-mt-3 ">
              {couponQuote && (
                <button
                  type="button"
                  data-testid="reset-coupon"
                  className="text-xs text-gray-600 border-b border-gray-400"
                  onClick={() => {
                    setLocalCoupon("");
                    setCouponQuote({});
                  }}
                >
                  Remover cupom
                </button>
              )}
              {(couponInfo?.info || couponInfo?.success) && (
                <span
                  data-testid="coupon-msg"
                  className={cn("block mt-2 text-sm font-semibold", {
                    "text-gray-800": couponInfo?.info,
                    "text-green-700": couponInfo?.success,
                  })}
                >
                  {couponInfo?.message}
                </span>
              )}
            </div>
          </Section>
        )}
        {!showCoupon && (
          <span
            className="block mb-4 text-xs text-center text-gray-600 cursor-pointer"
            onClick={() => {
              setShowCoupon(true);
              trackEvent({
                category: "checkout",
                action: "add_coupon",
              });
              setTimeout(() => {
                mainTab.current.scrollTo({
                  top: 9999,
                  left: 9999,
                  behavior: "smooth",
                });
              }, 100);
            }}
          >
            Tem um cupom? <span className="font-medium">Clique aqui.</span>
          </span>
        )}

        <Button
          primary
          data-testid="checkout-submit"
          type="submit"
          className="px-10 py-3 mb-2"
          label="Confirmar"
          disabled={disableInputs}
        />
      </form>
    </div>
  );
};

export default StepCheckout;
