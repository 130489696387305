import React from "react";

import logo from "./images/logo.png";
import logo2x from "./images/logo@2x.png";
import logo3x from "./images/logo@3x.png";

const Logo = () => {
  return (
    <div className="flex items-center justify-around flex-none p-1 bg-white">
      <img
        className="h-8 font-medium md:h-12"
        src={logo}
        alt="logo"
        srcSet={`${logo}, ${logo2x} 2x, ${logo3x} 3x`}
      />
    </div>
  );
};

export default Logo;
