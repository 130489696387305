// rejected	O pagamento foi recusado. O cliente pode fazer uma nova tentativa.
// cancelled	O pagamento foi cancelado por uma das partes ou expirou.
// refunded	O pagamento foi devolvido ao usuário.
// partially_refunded	Parte do pagamento foi devolvida ao usuário.
// charged_back	Um estorno foi feito no cartão de crédito do comprador.
// vacated	Ocorreu um erro interno.

const ERROR_MAPPING = {
  rejected: "Pagamento recusado pela sua operadora. Tente com outro cartão.",
  cancelled: "Pagamento cancelado",
};
export const mapError = (e) =>
  ERROR_MAPPING[e] || "Erro não identificado no pagamento";

export const getTokenFromMP = ({
  customerEmail,
  cardNumber,
  cvc,
  expiryDate,
  cardName,
  cpf,
  totals,
}) =>
  new Promise((res, rej) => {
    const $form = document.createElement("form");
    $form.method = "post";
    $form.id = "pay";
    $form.innerHTML = `
    <input type="hidden" id="email" value="${customerEmail}" />
    <input type="hidden" id="cardNumber" data-checkout="cardNumber" value="${cardNumber}" />
    <input type="hidden" id="securityCode" data-checkout="securityCode" value="${cvc}" />
    <input type="hidden" id="cardExpirationMonth" data-checkout="cardExpirationMonth" value="${expiryDate
      .split("/")[0]
      .padStart(2, "0")}" />
    <input type="hidden" id="cardExpirationYear" data-checkout="cardExpirationYear" value="${expiryDate
      .split("/")[1]
      .padStart(4, "20")}" />
    <input type="hidden" id="cardholderName" data-checkout="cardholderName" value="${cardName}" />
    <input type="hidden" id="docType" data-checkout="docType" value="CPF" />
    <input type="hidden" id="docNumber" data-checkout="docNumber" value="${cpf}" />
    <input type="hidden" id="installments" value="1" />
    <input type="hidden" id="amount" value="${totals.creditCard}" />
    <input type="submit" value="ok" />
    `;
    window.Mercadopago.createToken($form, (status, response) => {
      if (status !== 200 && status !== 201) {
        console.error(JSON.stringify(response, null, 1));
        rej(response);
      } else {
        res(response.id);
      }
      window.Mercadopago.clearSession();
    });
  });
