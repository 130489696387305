import React from "react";
import cn from "classnames";

const Alert = ({
  type,
  title = "Ops! Algo deu errado.",
  description = "Não foi possível processar sua transação. Entre em contato com a gente.",
}) => {
  return (
    <div
      className={cn("border-l-4 p-4", {
        "bg-orange-100 border-orange-500 text-orange-700": type === "warning",
        "bg-red-100 border-red-500 text-red-700": type === "error",
      })}
      role="alert"
    >
      <p className="font-bold">{title}</p>
      <p>{description}</p>
    </div>
  );
};

export default Alert;
