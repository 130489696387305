import React from "react";
import { Range, getTrackBackground } from "react-range";

const CustomRange = ({ label, labelColor, noSteps, ...rangeProps }) => {
  return (
    <div className="mb-6">
      <span className="block mb-6 text-xs font-semibold tracking-wide text-gray-700 uppercase">
        {label}
      </span>
      <div className="px-3 py-1">
        <Range
          {...rangeProps}
          //step={noSteps ? undefined : (rangeProps.max + rangeProps.min) / 10}
          renderTrack={({ props, children }) => (
            <div
              {...props}
              className="h-2 rounded-lg bg-gradient-r-pink-purple"
              style={{
                background: getTrackBackground({
                  values: rangeProps.values,
                  colors: ["#ed64a6", "#b38fe6"],
                  min: rangeProps.min,
                  max: rangeProps.max,
                }),
              }}
            >
              {children}
            </div>
          )}
          renderThumb={({ props }) => (
            <div
              {...props}
              className="w-8 h-8 border-4 border-white rounded-full bg-gradient-r-pink-purple"
            />
          )}
          render
        />
      </div>
    </div>
  );
};

export default CustomRange;
