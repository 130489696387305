import React from "react";
import cn from "classnames";
import MaskedInput from "react-input-mask";

const Input = React.forwardRef(
  (
    {
      label,
      hint,
      labelColor,
      button,
      options,
      radio,
      error,
      mask,
      warning,
      ...props
    },
    ref
  ) => {
    return (
      <div className="w-full mb-3">
        <span className="block mb-2 text-xs font-semibold tracking-wide text-gray-700 uppercase">
          {label}
          {error && (
            <span
              data-testid={`error-${props.name}`}
              className="ml-1 text-red-600 normal-case"
            >
              {error}
            </span>
          )}
        </span>
        <div className="flex flex-row items-center mr-3">
          {options && !radio && (
            <select
              ref={ref}
              className={cn("form-select w-full", {
                "text-gray-800": !error,
                "form-select-invalid": !!error,
                "bg-gray-300": props.disabled && !error,
                "bg-red-300": props.disabled && error,
              })}
              {...props}
            >
              {options.map((o) =>
                o.group ? (
                  <optgroup key={o.group} label={o.group}>
                    {o.items.map((i) => (
                      <option key={i.id || i} value={i.id || i}>
                        {i.text || i}
                      </option>
                    ))}
                  </optgroup>
                ) : (
                  <option key={o.id || o} value={o.id || o}>
                    {o.text || o}
                  </option>
                )
              )}
            </select>
          )}
          {options && radio && (
            <div className="block">
              {options.map((o) => (
                <div className="mt-1" key={o.id}>
                  <label className="inline-flex items-center">
                    <input
                      ref={ref}
                      type="radio"
                      className={cn("w-4 h-4 form-radio", {
                        "text-pink-500": !props.disabled,
                        "bg-gray-300 text-gray-500": props.disabled,
                      })}
                      {...props}
                      value={o.id}
                    />
                    <span className="ml-4 text-gray-700">{o.text}</span>
                  </label>
                </div>
              ))}
            </div>
          )}
          {!options && !mask && (
            <input
              ref={ref}
              type="text"
              className={cn("form-input w-full text-gray-800", {
                "rounded-r-none border-r-0 focus:shadow-none": !!button,
                "form-input-invalid": !!error,
                "bg-gray-300": props.disabled && !error,
                "bg-red-300": props.disabled && error,
              })}
              {...props}
            />
          )}
          {!options && mask && (
            <MaskedInput {...props} mask={mask} maskPlaceholder={null}>
              <input
                ref={ref}
                type="text"
                className={cn("form-input w-full text-gray-800", {
                  "rounded-r-none border-r-0 focus:shadow-none": !!button,
                  "form-input-invalid": !!error,
                  "bg-gray-300": props.disabled && !error,
                  "bg-red-300": props.disabled && error,
                })}
              />
            </MaskedInput>
          )}
          {button}
        </div>
        {hint && (
          <span className="pt-1 pl-2 text-xs font-medium text-gray-600 normal-case">
            {hint}
          </span>
        )}
        {warning && (
          <span className="pt-1 text-sm font-medium text-yellow-600 normal-case">
            {warning}
          </span>
        )}
      </div>
    );
  }
);
export default Input;
