import React from "react";

import Input from "./Input";
import { states } from "./br";
import { trackInitiateCheckout } from "./track";

const Shipping = ({ register, errors, disableInputs, getAddressFromCEP }) => {
  const [tracked, setTracked] = React.useState(false);
  return (
    <>
      <Input
        ref={register}
        label="Para quem é o quadro?"
        placeholder="Digite o nome de quem vai receber"
        name="nome_destinatario"
        error={errors.nome_destinatario?.message}
        disabled={disableInputs}
        onBlur={(e) => {
          if (!e.target.value || tracked) {
            return;
          }
          setTracked(true);
          trackInitiateCheckout();
        }}
      />
      <div className="flex flex-row items-end justify-between pr-3">
        <Input
          ref={register}
          mask="99999-999"
          label="CEP"
          name="cep"
          error={errors.cep?.message}
          inputMode="numeric"
          enterkeyhint="search"
          disabled={disableInputs}
          onKeyPress={(e) => {
            if (e.keyCode === 13) {
              e.preventDefault();
              getAddressFromCEP();
            }
          }}
        />
        <button
          className="flex-1 w-full h-10 px-3 py-1 mb-3 leading-tight text-gray-700 bg-white border rounded rounded-r-none shadow cursor-pointer focus:outline-none focus:border-blue-300 focus:border-r-0 disabled:opacity-50"
          disabled={disableInputs}
          onClick={(e) => {
            e.preventDefault();
            getAddressFromCEP();
          }}
        >
          Buscar
        </button>
      </div>
      <div>
        <Input
          ref={register}
          label="Rua"
          name="endereco"
          error={errors.endereco?.message}
          disabled={disableInputs}
        />
        <div className="flex flex-row items-center justify-between">
          <Input
            ref={register}
            label="Número"
            name="numero"
            error={errors.numero?.message}
            disabled={disableInputs}
          />
          <Input
            ref={register}
            label="Complemento"
            name="complemento"
            error={errors.complemento?.message}
            disabled={disableInputs}
          />
        </div>
        <Input
          ref={register}
          label="Bairro"
          name="bairro"
          error={errors.bairro?.message}
          disabled={disableInputs}
        />
        <Input
          ref={register}
          label="Cidade"
          name="cidade"
          error={errors.cidade?.message}
          disabled={disableInputs}
        />
        <Input
          ref={register}
          label="Estado"
          name="estado"
          error={errors.estado?.message}
          options={states}
          disabled={disableInputs}
        />
      </div>
    </>
  );
};

export default Shipping;
