import React from "react";

import Input from "./Input";

const Material = ({
  size,
  setSize,
  material,
  setMaterial,
  materials,
  sizes,
  frameProps,
}) => {
  return (
    <div className="flex flex-col justify-start">
      <Input
        name="material"
        label="Material"
        labelColor={frameProps.textColor}
        options={materials}
        value={material}
        onChange={(e) => setMaterial(e.target.value)}
      />
      <Input
        name="size"
        label="Tamanho"
        hint="Altura x Largura"
        labelColor={frameProps.textColor}
        options={sizes}
        value={size}
        onChange={(e) => setSize(e.target.value)}
        disabled={material === "download"}
      />
    </div>
  );
};

export default Material;
