import * as yup from "yup";

const validaCPF = (cpf) => {
  cpf = cpf?.replace(/[^0-9]+/g, "");
  if (
    !cpf ||
    cpf.length !== 11 ||
    cpf === "00000000000" ||
    cpf === "11111111111" ||
    cpf === "22222222222" ||
    cpf === "33333333333" ||
    cpf === "44444444444" ||
    cpf === "55555555555" ||
    cpf === "66666666666" ||
    cpf === "77777777777" ||
    cpf === "88888888888" ||
    cpf === "99999999999"
  ) {
    return false;
  }
  let soma = 0;
  let resto;
  for (let i = 1; i <= 9; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(9, 10))) {
    return false;
  }
  soma = 0;
  for (let i = 1; i <= 10; i++) {
    soma = soma + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  }
  resto = (soma * 10) % 11;
  if (resto === 10 || resto === 11) {
    resto = 0;
  }
  if (resto !== parseInt(cpf.substring(10, 11))) {
    return false;
  }
  return true;
};

yup.setLocale({
  mixed: {
    required: "*",
  },
  string: {
    // eslint-disable-next-line
    min: "mínimo de ${min} letras.",
  },
});

const requiredForCreditCard = {
  is: "cc",
  then: yup.string().required(),
};

export default yup.object().shape({
  quantity: yup.number().required().default(1),
  nome_destinatario: yup.string().required(),
  cep: yup
    .string()
    .required()
    .test("is-complete", "*", (v) => !v.includes("_")),
  estado: yup
    .string()
    .transform((v) => (v === "-" ? "" : v))
    .required(),
  cidade: yup.string().required(),
  bairro: yup.string().required(),
  endereco: yup.string().required().min(2),
  numero: yup.string().required(),
  complemento: yup.string(),
  nome: yup
    .string()
    .required()
    .test(
      "has-two-names",
      "INVÁLIDO. Use seu nome completo",
      (v = "") => v.split(" ").length > 1
    ),
  email: yup.string().email("INVÁLIDO").required(),
  telefone: yup
    .string()
    .required()
    .test("is-complete", "*", (v) => !v.includes("_")),
  cpf: yup
    .string()
    .required()
    .test("is-complete", "*", (v) => !v.includes("_"))
    .test("is-valid", "Inválido", validaCPF),
  paymentOption: yup.string().oneOf(["bankslip", "cc", "pix"]).required(),
  cardName: yup.string().when("paymentOption", requiredForCreditCard),
  cardNumber: yup.string().when("paymentOption", requiredForCreditCard),
  expiryDate: yup.string().when("paymentOption", requiredForCreditCard),
  cvc: yup.string().when("paymentOption", requiredForCreditCard),
  installments: yup.string().when("paymentOption", requiredForCreditCard),
});
