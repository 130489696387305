import React from "react";
import cn from "classnames";

const Button = React.forwardRef(
  ({ label, className = "", primary, disabled, ...props }, ref) => {
    return (
      <button
        ref={ref}
        className={cn(
          "block mx-auto shadow focus:shadow-outline focus:outline-none p-2 rounded disabled:opacity-50",
          className,
          {
            "bg-gray-200 text-gray-800": !primary,
            "bg-gradient-r-pink-purple text-white uppercase active:bg-gradient-r-pink-purple-darker": primary,
            "cursor-default": disabled,
          }
        )}
        {...props}
        disabled={!!disabled}
      >
        <span
          className={cn({
            "font-bold antialiased": primary,
          })}
        >
          {label}
        </span>
      </button>
    );
  }
);

export default Button;
