import React from "react";
import cn from "classnames";
import MicRecorder from "mic-recorder-to-mp3";

import Logo from "./Logo";
import CloseButton from "./CloseButton";
import Player from "./Player";
import { trackEvent } from "./track";
import Button from "./Button";
import useTrackErrorAndThrow from "./useTrackErrorAndThrow";

import nomicIOS1 from "./images/nomic-ios-1.jpg";
import nomicIOS2 from "./images/nomic-ios-2.jpg";
import nomicAndroid1 from "./images/nomic-android-1.jpg";
import nomicAndroid2 from "./images/nomic-android-2.jpg";

const IS_INSTAGRAM = navigator.userAgent.match(/instagram/i);
const IS_IOS = navigator.userAgent.match(/(iPhone|iPad)/i);
const IS_ANDROID = navigator.userAgent.match(/android/i);
const CAN_RECORD = !IS_INSTAGRAM && !!navigator?.mediaDevices?.getUserMedia;

const recorder = new MicRecorder({
  bitRate: 128,
});

const SoundRecord = ({ setSound, setSourceType }) => {
  const [record, setRecord] = React.useState(false);
  const [recording, setRecording] = React.useState(false);
  const [needsPermission, setNeedsPermission] = React.useState(false);
  const [ellapsed, setEllapsed] = React.useState(0);
  const trackAndThrowError = useTrackErrorAndThrow();
  const [file, setFile] = React.useState();
  React.useEffect(() => {
    if (!record) {
      setNeedsPermission(false);
    }
  }, [record]);

  React.useEffect(() => {
    if (!recording) {
      return;
    }
    trackEvent({
      category: "record",
      action: "start-timer",
    });
    setEllapsed(0);
    const intervalId = setInterval(() => {
      setEllapsed((e) => e + 100);
    }, 100);
    return () => {
      clearInterval(intervalId);
    };
  }, [recording]);
  React.useEffect(() => {
    if (ellapsed < 60000) {
      return;
    }
    trackEvent({
      category: "record",
      action: "60s-timeout",
    });
    setRecording(false);
  }, [ellapsed]);
  const [ellapsedSeconds, ellapsedMilliseconds] = (ellapsed / 1000)
    .toFixed(1)
    .toString()
    .split(".");
  return (
    <>
      <div
        role="button"
        onClick={() => {
          trackEvent({
            category: "record",
            action: "open-record",
          });
          setRecord(true);
        }}
        className="flex flex-col items-start justify-between h-40 p-4 font-bold text-white bg-orange-500 rounded-lg cursor-pointer hover:bg-orange-700"
      >
        <div className="p-2 mr-4 bg-orange-300 rounded">
          <svg viewBox="0 0 20 20" className="w-4 h-4 text-white fill-current">
            <path
              fillRule="evenodd"
              d="M9 18v-1.062C5.054 16.445 2 13.071 2 9h2v.002A5.997 5.997 0 0 0 10 15c3.307 0 6-2.685 6-5.998V9h2c0 4.079-3.06 7.445-7 7.938V18h3v2H6v-2h3zM6 4.007A4.006 4.006 0 0 1 10 0c2.21 0 4 1.794 4 4.007v4.986A4.006 4.006 0 0 1 10 13c-2.21 0-4-1.794-4-4.007V4.007z"
            />
          </svg>
        </div>
        <span className="text-xl font-light text-white">Gravar</span>
      </div>

      {CAN_RECORD && record && (
        <div className="absolute top-0 left-0 w-screen h-screen p-0 m-0 overflow-hidden bg-white lg:max-w-sm">
          <Logo />
          {!recording && (
            <div className="absolute top-0 right-0 pt-6 pr-6">
              <CloseButton
                onClick={() => {
                  setRecord(false);
                }}
              />
            </div>
          )}
          <div
            className="flex flex-col items-center justify-around p-4 text-center"
            style={{ height: IS_INSTAGRAM ? "80%" : "100%" }}
          >
            {!needsPermission && !file && (
              <h3
                className="font-light text-gray-900"
                style={{ fontVariantNumeric: "tabular-nums" }}
              >
                <span className="font-thin" style={{ fontSize: "12rem" }}>
                  {ellapsedSeconds}
                </span>
                <span className="text-3xl text-gray-400">.</span>
                <span className="text-3xl text-gray-400">
                  {ellapsedMilliseconds}
                </span>
                <span className="text-gray-300 text-md">s</span>
              </h3>
            )}
            {file && (
              <Player
                file={file}
                confirmSound={() => {
                  trackEvent({
                    category: "record",
                    action: "use-record",
                  });
                  setSound(file);
                  setSourceType("record");
                  setRecord(false);
                  setFile(undefined);
                }}
                cancelSound={() => {
                  trackEvent({
                    category: "record",
                    action: "cancel-record",
                  });
                  setEllapsed(0);
                  setFile(undefined);
                }}
              />
            )}
            {!needsPermission && !file && (
              <Button
                primary={!recording}
                className={cn(
                  "p-4 rounded-full shadow focus:outline-none flex items-center justify-center",
                  {
                    "bg-red-600": recording,
                  }
                )}
                label={
                  <div className="flex flex-row items-center justify-start">
                    <svg
                      viewBox="0 0 20 20"
                      className="w-12 h-12 text-white fill-current"
                    >
                      {!recording && (
                        <path
                          fillRule="evenodd"
                          d="M9 18v-1.062C5.054 16.445 2 13.071 2 9h2v.002A5.997 5.997 0 0 0 10 15c3.307 0 6-2.685 6-5.998V9h2c0 4.079-3.06 7.445-7 7.938V18h3v2H6v-2h3zM6 4.007A4.006 4.006 0 0 1 10 0c2.21 0 4 1.794 4 4.007v4.986A4.006 4.006 0 0 1 10 13c-2.21 0-4-1.794-4-4.007V4.007z"
                        />
                      )}
                      {recording && <rect width="100%" height="100%" />}
                    </svg>
                  </div>
                }
                onClick={async () => {
                  try {
                    if (!recording) {
                      await recorder.start();
                    } else {
                      const [buffer, blob] = await recorder.stop().getMp3();
                      const file = new File(buffer, "Gravação.mp3", {
                        type: blob.type,
                        lastModified: Date.now(),
                      });
                      setFile(file);
                    }
                    setRecording((r) => !r);
                  } catch (error) {
                    setRecording(false);
                    setFile(undefined);
                    if (error.name !== "NotAllowedError") {
                      trackAndThrowError(error);
                    } else {
                      setNeedsPermission(true);
                    }
                  }
                }}
              />
            )}
            {needsPermission && (
              <div className="p-4 text-center">
                <section className="text-center">
                  <p className="mb-6 text-xl text-gray-700">
                    Parece que seu navegador está bloqueando o acesso ao seu
                    microfone. Por favor, habilite o microfone e tente
                    novamente.
                  </p>
                  <p className="mb-6 text-xl text-gray-700">
                    Se tiver qualquer dúvida, entre em contato com a gente.
                  </p>
                  <Button
                    onClick={() => {
                      setRecord(false);
                    }}
                    primary
                    label="Fechar"
                  />
                </section>
              </div>
            )}
          </div>
        </div>
      )}
      {!CAN_RECORD && record && (
        <div className="absolute top-0 left-0 w-screen h-screen p-0 m-0 overflow-auto bg-white lg:max-w-sm">
          <Logo />
          <div className="p-4 text-center">
            <section className="text-center">
              <p className="mb-6 text-xl text-gray-700">
                {IS_INSTAGRAM && (
                  <>
                    Por motivos de segurança,{" "}
                    <strong>o Instagram não permite gravações</strong>. Para
                    gravar o seu som, clique para abrir essa página no seu
                    navegador.
                  </>
                )}
                {IS_INSTAGRAM && IS_IOS && (
                  <p className="my-3 text-xl text-gray-700">
                    <img src={nomicIOS1} alt="Acesse o menu de opções" />
                    <img src={nomicIOS2} alt="Clique para abrir no Safari" />
                  </p>
                )}
                {IS_INSTAGRAM && IS_ANDROID && (
                  <p className="my-3 text-xl text-gray-700">
                    <img src={nomicAndroid1} alt="Acesse o menu de opções" />
                    <img
                      src={nomicAndroid2}
                      alt="Clique para abrir no Safari"
                    />
                  </p>
                )}
                {!IS_INSTAGRAM && (
                  <>
                    Infelizmente, o seu aparelho não permite gravações. Por
                    favor, tente utilizar outro aparelho.
                  </>
                )}
              </p>

              <p className="mb-6 text-xl text-gray-700">
                Se tiver qualquer dúvida, entre em contato com a gente.
              </p>
              <Button
                onClick={() => {
                  setRecord(false);
                }}
                primary
                label="Fechar"
              />
            </section>
          </div>
        </div>
      )}
    </>
  );
};

export default SoundRecord;
